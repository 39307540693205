import React from 'react'
import { Checkbox } from 'antd'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import './MyDefaultCheckbox.scss'

const MyDefaultCheckbox = ({
  onChange,
  value,
  title,
  disabled,
  isForm,
  isLast,
  name,
}) => {
  return isForm ? (
    <MyDefaultFormItem name={name} valuePropName='checked' isLast={isLast}>
      <Checkbox
        className={'my_default_checkbox'}
        onChange={onChange}
        checked={value}
        disabled={disabled}
      >
        {title}
      </Checkbox>
    </MyDefaultFormItem>
  ) : (
    <Checkbox
      className={'my_default_checkbox'}
      onChange={onChange}
      value={value}
      disabled={disabled}
    >
      {title}
    </Checkbox>
  )
}

export default MyDefaultCheckbox
